<template>
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>ico-N/ico-24-password.gray</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="ico-N/ico-24-password.gray" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <rect id="Rectangle-11" stroke="currentColor" stroke-width="2" x="4" y="10" width="16" height="12" rx="2"></rect>
      <path
        d="M7,10 C7,8.34756874 7,6.98804623 7,5.92143247 C7,4.32151183 7.95456386,2 12,2 C16.0454361,2 17,4.68935073 17,5.92143247 C17,6.7428203 17,8.10234281 17,10"
        id="Path-105"
        stroke="currentColor"
        stroke-width="2"
      ></path>
      <circle id="Oval" fill="currentColor" cx="12" cy="16" r="2"></circle>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IcoPassword',
}
</script>

<style lang="scss" scoped></style>
